<template>
  <div>
    <!--div class="hovering__pad">
      <p class="fs-16">Как отправить заявки блогерам на сайте</p>
      <b-embed></b-embed>
    </!--div-->
    <div class="d-flex justify-content-between mb-4 align-items-center w-md-100">
      <h2 class="d-inline-block">
        В корзине {{ userCart.orders_length }}
        {{ literal.declension(userCart.orders_length, 'блогер') }}
      </h2>
      <b-button
        variant="outline-default"
        :disabled="!userCart.orders_length"
        @click="flushCart"
        class="ml-auto"
        >Очистить</b-button
      >
    </div>
    <template>
      <template v-if="userCart.orders_length">
        <div class="d-flex justify-content-start align-items-center mb-4 mt-4">
          <!--b-button
            v-if="ordersLeft >= 0"
            @click="setOrderWindow"
            :disabled="!userCart.orders_length"
            variant="yellow"
            class="py-3"
            >Отправить запрос</!--b-button
          >
          <b-button-- v-else @click="setOrderWindow" variant="outline-default" class="py-3"
            >Запросить за {{ Math.abs(ordersLeft) }}
            <img class="ml-1 d-inline-block mb-1" src="@main/assets/img/svg/energy.svg" alt=""
          /></b-button-->
          <span v-if="userCart.orders_left" style="max-width: 150px" class="gray-text fs-12 ml-3"
            >Сегодня осталось {{ userCart.orders_left }} из 20 бесплатных запросов</span
          >
          <span v-else style="max-width: 150px" class="gray-text fs-12 ml-3"
            >Бесплатные запросы сегодня закончились</span
          >
        </div>
      </template>
      <div v-if="reviews || formats" class="hovering__pad mb-4 w-md-100 order-container">
        <div class="order-wrapper mb-md-5" v-if="formats">
          <div class="p-3 order--cart__item order-item" v-for="format in formats" :key="format.id">
            <b-button class="mb-2" variant="link">{{ format.blogger }}</b-button>
            <p class="mb-3">
              <span class="fw-500">{{ getPrType(format) }}</span> —
              {{ format.uPrice.toLocaleString().replace(',', ' ') }} ₽
            </p>
            <div class="d-flex flex-row justify-content-left">
              <b-button
                @click="subtractCartFormats(format.id)"
                class="mr-3"
                variant="outline-default"
              >
                ❌ Убрать</b-button
              >
              <b-button
                @click="
                  () => {
                    toggleStatisticCardModal({
                      open: true,
                      context: { instaname: format.blogger },
                    });
                  }
                "
                variant="outline-default"
                >🦸 В карточку</b-button
              >
            </div>
          </div>
        </div>
        <div v-if="reviews">
          <div style="background: #fffce3" class="py-3 px-3 mb-2 not-reg-blck">
            <h2 class="mb-2">Блогеры, которых нет на сайте</h2>
            <p class="gray-text">
              Вы отправляете заявку блогерам на будущее, когда они зарегистрируются на сайте
            </p>
          </div>
          <div class="order-wrapper mb-md-5 mt-md-5">
            <div
              class="p-3 order--cart__item order-item"
              v-for="review in reviews"
              :key="review.advertiser"
            >
              <b-button class="mb-3" variant="link">{{ review.advertiser }}</b-button
              ><br />
              <b-button @click="subtractCartReviews(review.id)" variant="outline-default"
                >❌ Убрать</b-button
              >
            </div>
          </div>
        </div>
      </div>
      <div v-if="userCart.orders_length">
        <div class="d-flex justify-content-start align-items-center mb-3 mt-4 d-md-none">
          <!--b-button
            v-if="ordersLeft >= 0"
            @click="setOrderWindow"
            :disabled="!userCart.orders_length"
            variant="yellow"
            class="py-3"
            >Отправить запрос</!--b-button
          >
          <b-button-- v-else @click="setOrderWindow" variant="outline-default" class="py-3"
            >Запросить за {{ Math.abs(ordersLeft) }}
            <img class="ml-1 d-inline-block mb-1" src="@main/assets/img/svg/energy.svg" alt=""
          /></b-button-->
          <span v-if="userCart.orders_left" style="max-width: 150px" class="gray-text fs-12 ml-3"
            >Сегодня осталось {{ userCart.orders_left }} из 20 бесплатных запросов</span
          >
          <span v-else style="max-width: 150px" class="gray-text fs-12 ml-3"
            >Бесплатные запросы сегодня закончились</span
          >
        </div>
        <p class="fs-12 gray-text">
          Выбрано {{ userCart.orders_length }}
          {{ literal.declension(userCart.orders_length, 'блогер') }}. Будет потрачено:
          {{ freeRequestsCost }}
          бесплатных запросов.
          <span class="gray-text" style="display: contents" v-if="energyCost"
            >и {{ energyCost }} проверок блогеров
            <img
              style="height: 16px; width: 16px"
              class="d-inline-block"
              src="@main/assets/img/svg/energy.svg"
              alt=""
          /></span>
        </p>
      </div>
      <b-button v-else :to="{ name: 'reviews' }" variant="yellow" class="py-3 w-100 w-md-auto mb-2"
        >Найти блогеров для рекламы</b-button
      ></template
    >
    <!--Loader v-else /-->
  </div>
</template>

<script>
import { mapMutations, mapState, mapActions } from 'vuex';
import bot from '@main/api/bot';
// import Loader from '@main/components/Loader.vue';
import reviews from '@main/api/reviews';
import AdvertisingApplicationForm from '@main/components/forms/AdvertisingApplicationForm.vue';
import { isMobile } from 'mobile-device-detect';

export default {
  data: () => ({
    reviews: null,
    formats: null,
  }),
  components: {
    //  Loader,
  },
  methods: {
    ...mapActions('notifications', ['setWindow']),
    ...mapMutations('popups', ['toggleStatisticCardModal']),
    ...mapActions(['updateUserCart']),
    setOrderWindow() {
      this.setWindow({
        backMode: true,
        contentClass: 'overflow-auto pb-4',
        contentComponent: {
          component: AdvertisingApplicationForm,
          wrapper: { attrs: { class: 'px-4 pb-4' } },
        },
        topLess: !!isMobile,
        titleAttrs: {
          style: 'border-bottom: 1px solid #d5dfe4; font-size: 18px;',
          class: 'px-4 pb-2 pt-2 py-md-4',
        },
        title: 'Заполните поля заявки, чтобы блогеры смогли вам ответить',
      });
    },
    async subtractCartFormats(id) {
      await this.updateUserCart({
        ...this.userCart,
        formats: this.lodash.difference(this.userCart.formats, [id]),
      });
    },
    async subtractCartReviews(id) {
      await this.updateUserCart({
        ...this.userCart,
        reviews: this.lodash.difference(this.userCart.reviews, [id]),
      });
    },
    async flushCart() {
      await this.updateUserCart({
        ...this.userCart,
        reviews: [],
        formats: [],
      });
    },
    async loadFullData() {
      if (!this.userCart.reviews || !this.userCart.reviews.length) {
        this.reviews = null;
      } else {
        const revs = await reviews.list({ id__in: this.userCart.reviews.join(',') });
        this.reviews = revs.results;
      }
      if (!this.userCart.formats || !this.userCart.formats.length) {
        this.formats = null;
      } else {
        const formats = await bot.formats.list({ id: this.userCart.formats });
        this.formats = formats.results;
      }
    },
    getPrType(prType) {
      const { choices } = this.options.pr_types.child.children.kind;
      const values = choices.filter((val) => val.value === prType.kind);
      let namestring = values[0].display_name;
      if ((values[0].value === '1' || values[0].value === '4') && prType.duration) {
        namestring = `${namestring} ${prType.duration ? prType.duration : ''} сек.`;
      }
      return namestring;
    },
  },
  computed: {
    ...mapState(['userCart', 'bloggerOptions']),
    options() {
      return this.bloggerOptions.actions.POST;
    },
    ordersLeft() {
      return this.userCart.orders_left - this.userCart.orders_length;
    },
    freeRequestsCost() {
      return this.userCart.orders_left - Math.max(0, this.ordersLeft);
    },
    energyCost() {
      return Math.abs(Math.min(0, this.ordersLeft));
    },
  },
  async mounted() {
    await this.loadFullData();
    console.log(this.reviews);
  },
  watch: {
    userCart(val) {
      this.loadFullData();
    },
  },
};
</script>

<style lang="scss" scoped>
.order--cart__item:not(:last-child) {
  border-bottom: 1px solid #d5dfe4;
}
.not-reg-blck:first-child {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
@media (min-width: 768px) {
  .not-reg-blck {
    border-radius: 10px !important;
  }
  .order-container {
    background-color: transparent !important;
    box-shadow: none !important;
  }
  .order-item {
    width: 318px;
    background-color: #fff;
    box-shadow: 0 3px 10px rgb(0 73 129 / 10%);
    border-radius: 10px;
  }
  .order-wrapper {
    display: grid;
    grid-gap: 15px;
    grid-template-columns: repeat(auto-fill, minmax(318px, 1fr));
  }
}
</style>
